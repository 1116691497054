<template>
    <v-content>
        <div v-if="! moduleReport">
            <v-breadcrumbs :items="items" large></v-breadcrumbs>
            <v-row>
                <!--  <v-col
                          class="viewCard"
                          cols="auto"
                          v-bind:key="index"
                          v-for="(title, index) in modules"
                  >
                      <v-card
                              :elevation="10"
                              @click="goToModuleReport(title.name)"
                              class="blue darken-1 text-center"
                              height="100"
                              width="200"
                      >
                          <v-list-item three-line>
                              <v-list-item-content>
                                  <v-list-item-title
                                          class="white&#45;&#45;text subtitle-1 mt-1 pa-4"
                                  >{{title.name}}
                                  </v-list-item-title>
                                  <v-list-item-subtitle class="white&#45;&#45;text header-6 mt-1">
                                  </v-list-item-subtitle>
                              </v-list-item-content>
                          </v-list-item>
                      </v-card>
                  </v-col>-->
                <v-col cols="2"
                       v-for="title in titles"
                       v-bind:key="title.link"
                       class="viewCard">
                    <v-tooltip right>
                        <template v-slot:activator="{ on }">
                            <router-link :to="title.link">
                                <HoverCard :cardData="title"></HoverCard>
                                <!--<v-card :elevation="10"
            class="blue darken-1 text-center"
            height="100"
            width="200">
        <v-list-item three-line>
            <v-list-item-content>
                <v-list-item-title class="white--text subtitle-1 mt-1 pa-4">
                    {{title.name}}
                </v-list-item-title>
                <v-list-item-subtitle class="white--text header-6 mt-1">
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
    </v-card>-->
                            </router-link>
                        </template>
                    </v-tooltip>
                </v-col>
            </v-row>
        </div>
        <!-- <component :is="componentName"
                    :moduleName="moduleName"
                    @goBackToReportDashboard="goBackToReportDashboard"
                    @goBackToReportSelection="goBackToReportSelection"
                    @goToReport="goToReport"
                    v-else
         ></component>-->
    </v-content>
</template>

<script>
    import HoverCard from '@/components/Basic/HoverCard'
    export default {
        name: "Dashboard",
        watch: {},
        components: {
            /*  selectReportTypeComponent: () => import(/!* webpackChunkName: "selectReportTypeComponent" *!/ '../Modules/Module'),
              monthlyYearlyReport: () => import(/!* webpackChunkName: "monthlyYearlyReport" *!/ '../History/HistoryList'),
              reportHeaderSetting: () => import(/!* webpackChunkName: "reportHeaderSetting" *!/ '../ReportColumn/TrekkingList'),
              SaarcReport: () => import(/!* webpackChunkName: "SaarcReport" *!/ '../HistorySaarc/HistoryList'),*/
            HoverCard
        },
        computed: {
            titles() {
                return [
                    {
                        name: this.$t('modules_name.airports'),
                        link: "/Filter/Airport",
                        icon:"mdi-radio-tower"
                    },
                    {
                        name: this.$t('airline'),
                        link: "/Filter/Ariline",
                        icon:"mdi-airplane"
                    },
                    //{
                    //    name: this.$t('modules_name.customer_visit_countrywise'),
                    //    link: "/IndividualHomestayReport/customerVisitCountryWise"
                    //},
                   
                ]
            },
            items() {
                return [
                    {
                        text: this.$t('dashboard'),
                        to: "/AirportDashboard",
                        disabled: false,
                        exact: true
                    },
                    {
                        text: this.$t('dashboard'),
                        disabled: true
                    }
                    ]
            }
        },
        data() {
            return {

                /*    modules: [
                        {
                            name: "Trekking",
                        }
                    ],*/
                moduleReport: false,
                moduleName: '',
                componentName: ''
            }
        },
        mounted() {
            this.formData()
        },
        methods: {
            formData() {

            },

            goBackToReportSelection() {
                this.componentName = 'selectReportTypeComponent'
            },

            goBackToReportDashboard() {
                this.moduleReport = false
            },

            goToReport(type) {
                this.componentName = type
                console.log('this,moduleName: ', this.moduleName)
            },

            goToModuleReport(moduleName) {
                this.moduleName = moduleName
                this.componentName = 'selectReportTypeComponent'
                this.moduleReport = true
            }
        }
    };
</script>

<style lang="scss" scoped>
    a {
        text-decoration: none;
    }
</style>